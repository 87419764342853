.activeStyle{
    text-decoration: none;
}

.activeStyle .MuiListItemButton-root{
    color: #0063d4;
    background: #0063d420;
}

.activeStyle .MuiListItemButton-root .MuiListItemIcon-root{
    color: #0063d4;
}

.notActiveStyle .MuiListItemButton-root .MuiListItemIcon-root{
    color: #003876;
}

.notActiveStyle{
    text-decoration: none;
    color: #003876 !important;
}

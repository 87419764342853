@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.swal2-container {
    z-index: 1600 !important;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


.select__menu {
    z-index: 1600 !important;
}

.item-no-padding {
    padding: 0 !important;
}

.item-no-padding-top {
    padding-top: 0 !important;
}

.item-no-margin {
    margin: 0 !important;
}

.item-no-border {
    border: 0 !important;
}

.item-no-shadow {
    box-shadow: none !important;
}

.item-no-radius {
    border-radius: 0 !important;
}

.item-no-border-radius {
    border-radius: 0 !important;
}

.MuiTableSortLabel-icon {
    color: transparent !important;
    opacity: 1 !important;
}

.txt-primary {
    color: #003876;
}
.txt-success {
    color: #2ecc71;
}
.txt-secondary {
    color: #0087ff;
}
.txt-error {
    color: #f4403d;
}
.txt-info {
    color: #23c3df;
}

input, select {
    width: 100%;
    padding: 7px;
    box-sizing: border-box;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid #dadada;
    background: #f1f3f6;
}

.iconTime {
    background-image: url('../src/assets/time.png');
    background-position: 5px 5px; 
    background-size: 20px;
    background-repeat: no-repeat;
    text-align: right;
}
